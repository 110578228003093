<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 profile-screen delegates-screen full-width gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_profile-help-online-delegates'
        }"
      >
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("profile.profile.my-delegates") }}
        </div>
        <template v-slot:right>
          <button @click="search = !search">
            <icon icon="#cx-hea1-search" />
          </button>
          <router-link
            :to="{
              name: 'r_profile-my-delegates-add'
            }"
          >
            <icon icon="#cx-hea1-add" />
          </router-link>
        </template>
      </top-header-menu-wrapper>
      <search
        v-if="search"
        @changedInput="modifySearchQuery"
        @clear="searchQuery = ''"
        :simpleSearch="true"
      ></search>
    </template>
    <div
      v-if="listOfDelegates && listOfDelegates.length"
      class="delegates-body"
    >
      <!-- Header -->
      <ul class="clebex-item-section pill delegates-header">
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper grey-bg">
            <!-- First name -->
            <span class="label">
              <span class="highlight">{{
                displayLabelName("profile.delegate.name")
              }}</span>
            </span>
            <!-- Email -->
            <span class="label">
              <span class="highlight">{{
                displayLabelName("profile.profile.email")
              }}</span>
            </span>
            <!-- Permission -->
            <span class="label">
              <span class="highlight">{{
                displayLabelName("profile.delegate.permission")
              }}</span>
            </span>
            <!-- Start date -->
            <span class="label">
              <span class="highlight">{{
                displayLabelName("profile.delegate.from")
              }}</span>
            </span>
            <!-- End date -->
            <span class="label">
              <span class="highlight">{{
                displayLabelName("profile.delegate.to")
              }}</span>
            </span>
          </div>
        </li>
      </ul>
      <!-- Items -->
      <ul class="clebex-item-section pill mt-0 mb-0">
        <li
          v-for="item in listOfDelegates"
          :key="item.id"
          class="clebex-item-section-item"
        >
          <router-link
            :to="{
              name: 'r_profile-my-delegates-update',
              params: { delegationId: item.id }
            }"
            class="clebex-item-content-wrapper"
          >
            <div class="delegates-item-column">
              <div class="declaration-checkbox-wrapper">
                <div
                  @click.stop="setSelectedDelegates(item.id)"
                  class="checkbox tiny alt block"
                >
                  <input
                    @click.stop
                    type="checkbox"
                    :id="`delegate-id-${item.id}`"
                    name="user"
                  />
                  <label :for="`delegate-id-${item.id}`">
                    <svg-icon icon="checkmark-strait"></svg-icon>
                  </label>
                </div>
              </div>
              <span class="column-name"
                >{{ displayLabelName("profile.delegate.name") }}:
              </span>
              <span class="highlight"
                >{{ item.delegate.first_name }}
                {{ item.delegate.last_name }}</span
              >
            </div>
            <div class="delegates-item-column">
              <span class="column-name"
                >{{ displayLabelName("profile.profile.email") }}:
              </span>
              <span class="highlight">{{ item.delegate.email }}</span>
            </div>
            <div class="delegates-item-column">
              <span class="column-name"
                >{{ displayLabelName("profile.delegate.permission") }}:
              </span>
              <span class="highlight">{{ item.permission.name }} </span>
            </div>
            <div class="delegates-item-column">
              <span class="column-name"
                >{{ displayLabelName("profile.delegate.from") }}:
              </span>
              <span class="highlight"
                >{{ displayDate(item.datetime_from) }}
              </span>
            </div>
            <div class="delegates-item-column">
              <span class="column-name"
                >{{ displayLabelName("profile.delegate.to") }}:
              </span>
              <div class="highlight">
                {{ displayDate(item.datetime_to) }}
              </div>
              <icon
                v-if="
                  $route.params &&
                    $route.params.delegationId &&
                    $route.params.delegationId == item.id
                "
                icon="#cx-app1-checkmark"
                width="18"
                height="18"
                class="declaration-arrow"
              />
              <icon
                v-else
                icon="#cx-app1-arrow-right-12x12"
                width="12"
                height="12"
                class="declaration-arrow"
              ></icon>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
    <screen-modal
      v-if="showDeleteModal"
      class="confirm-modal"
      type="success"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancelButtonLabel="displayLabelName('global.buttons.cancel')"
      :confirm-action="deleteAllDelegations"
      :show="showDeleteModal"
      @close="showDeleteModal = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("profile.delegate.delete-title") }}
      </h3>
      <p class="text">
        {{ displayLabelName("profile.delegate.delete-action-text") }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>
    <template v-slot:footer
      ><nav v-if="delegationIds.length" class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button class="action-btn" @click="showDeleteModal = true">
              <icon icon="#cx-app1-delete-02-14x14" />
            </button>
            <span class="action-btn deleted-count">{{
              "(" + delegationIds.length + "/" + listOfDelegates.length + ")"
            }}</span>
          </li>
        </ul>
      </nav></template
    >
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions, mapGetters } from "vuex";
import { formatDate } from "@/services/helpers";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "ProfileMyDelegates",
  mixins: [helpOnlineMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    Search: defineAsyncComponent(() => import("@/components/search/Search"))
  },
  data() {
    return {
      search: false,
      searchQuery: "",
      delegationIds: [],
      showDeleteModal: false,
      helpSlug: "profile-delegates"
    };
  },
  created() {
    this.setPaginationData(null);
    this.setInitialValues();
  },
  computed: {
    ...mapGetters("settings", ["globalDateFormat"]),
    ...mapState("delegates", ["myDelegates"]),
    listOfDelegates() {
      if (this.myDelegates && this.myDelegates.length) {
        const query = this.searchQuery;
        if (query && query.length > 1) {
          return this.myDelegates.filter(item =>
            `${item.delegate.first_name} ${item.delegate.last_name} ${item.delegate.email} ${item.permission.name}`
              .toLowerCase()
              .includes(query.toLowerCase())
          );
        } else {
          return this.myDelegates;
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions("delegates", ["getDelegations", "deleteDelegations"]),
    ...mapActions("pagination", ["setPaginationData"]),
    modifySearchQuery(searchQuery) {
      this.searchQuery = searchQuery;
    },
    setInitialValues() {
      this.getDelegations();
      if (!this.isMobileDevice()) {
        this.$store.commit("global/setIsFullWidthScreen", true, {
          root: true
        });
      }
      this.modifySearchQuery("");
    },
    displayDate(date) {
      if (!date) {
        return "";
      }
      return formatDate(date, this.globalDateFormat);
    },
    setSelectedDelegates(id) {
      if (this.delegationIds.includes(id)) {
        this.delegationIds = this.delegationIds.filter(el => el !== id);
      } else {
        this.delegationIds.push(id);
      }
    },
    deleteAllDelegations() {
      this.deleteDelegations(this.delegationIds).then(
        () => (this.delegationIds = [])
      );
    }
  },
  beforeUnmount() {
    if (!this.isMobileDevice()) {
      this.$store.commit("global/setIsFullWidthScreen", false, {
        root: true
      });
    }
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
<style>
.deleted-count {
  position: relative;
  top: -4px;
  margin: 5px 8px;
  padding-left: 0px !important;
  margin-left: 0px;
}
</style>
